import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getSrc } from 'gatsby-plugin-image';
import Content from './Content';

function BlogPostTemplate({
  content,
  contentComponent,
  description,
  featuredimage,
  title,
  tags,
}) {
  const PostContent = contentComponent || Content;
  const featuredImageSrc = getSrc(featuredimage);

  return (
    <>
      <Helmet titleTemplate="%s | Sierra Swag">
        <title>{`${title}`}</title>
        <meta
          name="description"
          content={`${description}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {/* <meta property="og:url" content="/" /> */}
        <meta property="og:image" content={featuredImageSrc} />
      </Helmet>
      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                {title}
              </h1>
              <p>{description}</p>
              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map((tag) => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.object.isRequired,
  contentComponent: PropTypes.func,
};

export default BlogPostTemplate;