import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import BlogPostTemplate from '../components/BlogPost';
import { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';

function BlogPost({ data }) {
  const {
    html,
    frontmatter: {
      description,
      featuredimage,
      title,
      tags,
    },
  } = data.markdownRemark;
  
  return (
    <Layout>
      <BlogPostTemplate
        content={html}
        contentComponent={HTMLContent}
        description={description}
        featuredimage={featuredimage}
        title={title}
        tags={tags}
      />
    </Layout>
  );
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
          childImageSharp {
            gatsbyImageData(
              aspectRatio: 1
              width: 600
              quality: 100
            )
          }
        }
      }
    }
  }
`;

export default BlogPost;
